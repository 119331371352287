import React, { useState } from 'react';
import PropTypes from 'prop-types';

const SiteContext = React.createContext({
  isSidebarOpen: false,
  setSidebarOpen: () => {},
});

const SiteProvider = ({ children }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  return (
    <SiteContext.Provider
      value={{
        isSidebarOpen,
        setSidebarOpen,
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};

SiteProvider.propTypes = {
  children: PropTypes.node,
};

export default SiteContext;
export { SiteProvider };
